import { createContext, useContext, useState } from 'react';

// Create result context
const resultsContext = createContext();

/**
 * Result context provider JSX element
 * @param {*} children JSX element children of context probider
 */
function ProvideResult({ children }) {
  const res = useProvideResult();
  return (
    <resultsContext.Provider value={res}>{children}</resultsContext.Provider>
  );
}

/**
 * Result context consummer hook
 */
function useResult() {
  return useContext(resultsContext);
}

/**
 * Result context provider logic
 */
function useProvideResult() {
  const [ result, setRes ] = useState(null);

  const setResult = (data, cb) => {
    setRes(data);
    cb();
  };

  const cleanResult = (cb) => {
    setRes(null);
    cb();
  };

  return {
    result,
    setResult,
    cleanResult
  };
}

export { ProvideResult, useResult };
