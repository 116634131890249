import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { useResult } from '../result_context';
import axios from 'axios';
require('dotenv').config();

/**
 * Results page component
 */
function Results() {
  const genres = [
    'Afrobeat',
    'Blues',
    'Classical',
    'Country',
    'Coupé Décalé',
    'Disco',
    'Hip Hop',
    'Jazz',
    'Metal',
    'Pop',
    'Reggae',
    'Rock',
    'Rumba'
  ];
  let result = useResult();
  let errorMessage = <span>Server error</span>;
  const history = useHistory();
  const predicted = result.result;
  const logMe = predicted ? { predicted: predicted.text } : {};
  const [ values, setValues ] = useState({});
  const [ extraRequired, setExtraRequired ] = useState(false);
  const [ selectValue, setSelectValue ] = useState('');
  const serverAddress = process.env.REACT_APP_REVIEW_ADDRESS;

  /**
   * Handle form submit 
   * @param {Event} event form submit button click event
   */
  function handleSubmit(event) {
    const form = event.currentTarget;
    const valid = form.checkValidity();
    if (valid) {
      event.preventDefault();
      event.stopPropagation();
      if (values['agree'] === 'true') {
        submitToServer({ ...logMe, agree: true });
      } else {
        submitToServer({ ...logMe, ...values });
      }
    }
  }

  /**
   * 
   * @param {Map} review Map of review contents
   */
  function submitToServer(review) {
    axios.post(`${serverAddress}`, JSON.stringify(review), {
      headers: { 'Content-Type': 'application/json' }
    });
    history.push('/');
  }

  /**
   * Update form choices
   * @param {Event} event form object change event
   */
  function updateChoice(event) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  if (result.result) {
    if (result.result.error) {
      switch (result.result.error) {
        case 416:
          errorMessage = (
            <span>
              File not recognized as audio. Please try using a {' '}
              <a
                href={process.env.REACT_APP_WAV_URL}
                target='_blank'
                rel='noreferrer'
              >
                wav
              </a>
              <span> or </span>
              <a
                href={process.env.REACT_APP_MP3_URL}
                target='_blank'
                rel='noreferrer'
              >
                mp3
              </a>{' '}
              file.
            </span>
          );
          break;
        case 419:
          errorMessage = (
            <p>
              Audio length detected to be too short. If this is a mistake, try
              using a file that is not exactly 30 seconds long.
            </p>
          );
          break;

        case 512:
          errorMessage = (
            <p>Feature gathering failed. Please inspect your file</p>
          );
          break;

        default:
          errorMessage = (
            <p>Feature gathering failed. Please inspect your file</p>
          );
          break;
      }
      return (
        <div>
          <h4>Oops! Prediction Failed</h4>
          <span>
            <strong>Reason: </strong>
            {errorMessage}
          </span>
          <div>
            <a href='/' onClick={() => history.push('/')}>
              Retry
            </a>
          </div>
          <p>
            If error persists, consider{' '}
            <a
              href='https://github.com/batchema/apollos-ear/issues/new'
              target='_blank'
              rel='noreferrer'
            >
              submitting an issue
            </a>
          </p>
        </div>
      );
    } else
      return (
        <div>
          <div>
            <h1>Here is the result!</h1>
            <p style={{ fontSize: 'large' }}>
              Predicted Genre:{' '}
              <a
                href={predicted['link']}
                target='_blank'
                rel='noreferrer'
                style={{ textTransform: 'capitalize' }}
              >
                {predicted['text']}
              </a>
            </p>

            <Form
              style={{
                marginTop: '2rem',
                marginBottom: '1rem',
                maxWidth: '30rem',
                padding: '1rem',
                backgroundColor: '#f2f2f2'
              }}
              onSubmit={handleSubmit}
            >
              <Form.Group controlId='correctNess'>
                <Form.Control.Feedback type='invalid'>
                  Field required
                </Form.Control.Feedback>
                <Form.Label>Do you agree with the result?</Form.Label>
                <Form.Check
                  required
                  name='agree'
                  type='radio'
                  label='Yes'
                  id='yesRadio'
                  value={true}
                  onChange={(e) => {
                    setExtraRequired(false);
                    updateChoice(e);
                  }}
                />
                <Form.Check
                  name='agree'
                  type='radio'
                  label='No'
                  id='noRadio'
                  value={false}
                  onChange={(e) => {
                    setExtraRequired(true);
                    updateChoice(e);
                  }}
                />
              </Form.Group>
              <div>
                <Form.Label>
                  If you DO NOT agree with the predicted genre
                </Form.Label>
                <div>
                  <Form.Group controlId='correctGenre'>
                    <Form.Label>What is the correct genre?</Form.Label>
                    <Form.Control
                      disabled={!extraRequired}
                      as='select'
                      style={{ maxWidth: '15rem' }}
                      onChange={(event) => {
                        setSelectValue(event.target.value);
                        updateChoice(event);
                      }}
                      required={extraRequired}
                      value={selectValue}
                      name='correct_genre'
                    >
                      <option disabled value='' key='blank'>
                        Select a genre
                      </option>
                      {genres.map((genre) => {
                        return <option>{genre}</option>;
                      })};
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId='understandable'>
                    <Form.Label>
                      Is the predicted genre an arguably correct prediction?
                    </Form.Label>
                    <Form.Check
                      required={extraRequired}
                      disabled={!extraRequired}
                      name='arguable'
                      type='radio'
                      label='Yes'
                      id='yesRadio'
                      value={true}
                      onChange={updateChoice}
                    />
                    <Form.Check
                      disabled={!extraRequired}
                      name='arguable'
                      type='radio'
                      label='No'
                      id='noRadio'
                      value={false}
                      onChange={updateChoice}
                    />
                  </Form.Group>
                </div>
              </div>
              <Button variant='primary' type='submit'>
                Submit
              </Button>
            </Form>

            <Link to='/'>Predict another song</Link>
          </div>
        </div>
      );
  } else {
    return <Redirect to={{ pathname: '/' }} />;
  }
}

export default Results;
