import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
require('dotenv').config();

/**
 * Header component
 */
function Header() {
  return (
    <Navbar bg='primary' expand='lg' variant='dark'>
      {/* <Navbar.Brand style={{ marginLeft: '10%' }}> */}
      {/* <Navbar.Brand>Hey</Navbar.Brand> */}
      <Link to='/' className='navbar-brand' style={{ fontSize: '2rem' }}>
        Appollo's Ear
        {/* <span style={{ fontSize: '2rem' }}>Apollo's Ears</span> */}
      </Link>
      {/* </Navbar.Brand> */}
      <Navbar.Text
        style={{
          textAlign: 'left',
          marginRight: '10%'
        }}
      >
        <a
          href={process.env.REACT_APP_REPO_URL}
          target='_blank'
          rel='noreferrer'
          style={{
            fontSize: '1.5rem'
          }}
        >
          How it works
        </a>
      </Navbar.Text>
    </Navbar>
  );
}

export default Header;
