import axios from 'axios';
import React, { useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useResult } from '../result_context';
require('dotenv').config();

/**
 * Home page component
 */
function Home() {
  const [ selectedFile, setSelectedFile ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const history = useHistory();
  const _res = useResult();
  const serverAddress = process.env.REACT_APP_PREDICTION_ADDRESS;

  /**
   * Send an http request to the server
   * with submission file to get genre 
   * @param {File} file 
   */
  function getResults(file) {
    let formData = new FormData();
    formData.append('file', file);
    axios
      .post(`${serverAddress}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((res) => {
        console.log('hi');
        console.log(res);
        _res.setResult(res.data, () => {
          history.push('/results');
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
          _res.setResult({ error: err.response.status }, () => {
            history.push('/results');
          });
        } else {
          _res.setResult({ error: 500 }, () => {
            history.push('/results');
          });
        }
      });
  }

  /**
   * Handle file input events
   * @param {Event} event 
   */
  function handleInput(event) {
    const file = event.target.files[0];
    if (file.size > 52428800) {
      event.target.value = null;
      setSelectedFile(null);
      alert(
        'file size cannot be greater than 50 MB. Please Select another file'
      );
    } else {
      setSelectedFile(event.target.files[0]);
    }
  }

  return (
    <div>
      <h2>Welcome to Apollo's Ear!</h2>
      <p>Just upload an audio file and get the genre!</p>
      <Form
        style={{
          marginTop: '1.5rem'
        }}
      >
        <Form.Group>
          <label
            htmlFor='audiofile'
            style={{
              display: 'block'
            }}
          >
            Choose an audio file (Must be at least 30 seconds in length):
          </label>
          <input
            type='file'
            accept='audio/*'
            id='audiofile'
            onChange={handleInput}
            disabled={loading}
          />
          <div id='file Preview' />
        </Form.Group>
        {!loading ? (
          <Button
            variant='primary'
            type='submit'
            onClick={(e) => {
              e.preventDefault();
              if (selectedFile != null) {
                setLoading(true);
                getResults(selectedFile);
              }
            }}
          >
            Submit
          </Button>
        ) : (
          <Button variant='primary' disabled>
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
            Please wait about a minute.{'\n'}
            Loading results...
          </Button>
        )}
      </Form>
    </div>
  );
}

export default Home;
