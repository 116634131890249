import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import Footer from './static/footer';
import Header from './static/header';
import Home from './pages/home';
import Results from './pages/results';

function App() {
  return (
    <div>
      <Header />
      <div style={{ marginLeft: '10%', marginBottom: '10%' }}>
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route exact path='/results'>
            <Results />
          </Route>
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
