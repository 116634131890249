import React from 'react';
import { Navbar } from 'react-bootstrap';
require('dotenv').config();

/**
 * Sticky Footer component
 */
function Footer() {
  return (
    <Navbar
      bg='primary'
      variant='dark'
      fixed='bottom'
      className='justify-content-center'
      style={{ clear: 'both', overflow: 'auto' }}
      expand='lg'
    >
      <Navbar.Text>
        © Copyright 2020-{new Date().getFullYear()}
        <a
          href={process.env.REACT_APP_PERSONAL_WEBSITE}
          target='_blank'
          rel='noreferrer'
          style={{
            marginLeft: '.25rem'
          }}
        >
          Batchema Sombie
        </a>
        . All rights reserved.
      </Navbar.Text>
    </Navbar>
  );
}

export default Footer;
